import styles from "./LoginPage.module.css";
import State from "../../state";
import config from "../../config";

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

export default function LoginPage() {
	return (
		<div className={styles.page}>
			<div className={styles.logo}>{config.site.name}</div>
			<LoginForm />
		</div>
	);
}

const LoginForm = observer(() => {
	const { loggingIn, loginError } = State;
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	function handleSubmit(evt) {
		evt.preventDefault();
		if (username && password) {
			State.logIn(username, password);
		}
	}

	const disabled = !username || !password || loggingIn;

	return (
		<form className={styles.form} onSubmit={handleSubmit}>
			<input
				type="text"
				className={styles.form__input}
				placeholder="User name"
				value={username}
				onChange={(evt) => setUsername(evt.target.value)}
			/>
			<input
				type="password"
				className={styles.form__input}
				placeholder="Password"
				onChange={(evt) => setPassword(evt.target.value)}
			/>
			<button
				type="submit"
				className={classnames(
					styles.form__submit,
					disabled ? styles.disabled : null
				)}
			>
				{"Sign In"}
			</button>
			{config.site.login_footer_message ? (
				<div className={styles.form__footer_message}>
					{config.site.login_footer_message}
				</div>
			) : null}
			<div className={styles.form__error}>{loginError}</div>
		</form>
	);
});
