import styles from "./SiteHeader.module.css";
import State from "../../state";
import config from "../../config";

import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React from "react";

function SiteHeader() {
	const { coins } = State;

	return (
		<header className={styles.header}>
			<div className={styles.inner}>
				<Link to="/" className={styles.siteName}>
					{config.site.name}
				</Link>
				{config.pages.map(({ url, title }) => (
					<Link to={url} key={url} className={styles.link}>
						{title}
					</Link>
				))}
				<div className={styles.right}>
					<div className={styles.coins}>
						{(coins || 0).toLocaleString()}
					</div>
					<div
						className={styles.logoutButton}
						onClick={() => State.logOut()}
					>
						{"Sign Out"}
					</div>
				</div>
			</div>
		</header>
	);
}

export default observer(SiteHeader);
