import styles from "./MarketplaceWebsite.module.css";
import SiteHeader from "./layout/SiteHeader";
import LoginPage from "./pages/LoginPage";
import ItemsPage from "./pages/ItemsPage";
import ItemPage from "./pages/ItemPage";
import State from "../state";
import config from "../config";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import urljoin from "url-join";
import React from "react";

export default observer(() => (
    <Router>
        <div className={classnames(styles.app, config.site.theme)}>
            <SiteContent />
            {config.widget ? (
                <Widget
                    config={config.widget}
                    storyConfig={config.story}
                    context={{
                        loggedIn: State.loggedIn,
                        sessionId: State.sessionId,
                    }}
                />
            ) : null}
        </div>
    </Router>
));

const SiteContent = observer(() => {
    if (!State.loggedIn) {
        return <LoginPage />;
    }

    return (
        <>
            <SiteHeader />
            <Switch>
                {config.pages.map((page) => (
                    <Route path={page.url} key={page.url} exact>
                        <ItemsPage {...page} />
                    </Route>
                ))}
                {config.pages.map((page) => (
                    <Route
                        path={urljoin(page.url, ":itemId")}
                        key={`${page.url}__item`}
                        exact
                    >
                        <ItemPage page={page} {...page} />
                    </Route>
                ))}
                <Route>
                    <Redirect to={config.pages[0].url} />
                </Route>
            </Switch>
        </>
    );
});
