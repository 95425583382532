import "./index.css";
import config from "./config";
import MarketplaceWebsite from "./components/MarketplaceWebsite";
import State from "./state";

import * as Backend from "@faintlines/backend-client";
import { initSentry } from "@faintlines/monitoring";

import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

initSentry();

Backend.configure({
	storyId: config.story.id,
	assetId: config.story.assetId,
});

if (process.env.NODE_ENV === "production" && config.backendUrl) {
	Backend.configure({
		baseUrl: config.backendUrl,
	});
}

State.refresh();

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<Helmet>
				<title>{config.site.name}</title>
				{config.site.description ? (
					<meta
						name="description"
						content={config.site.description}
					/>
				) : null}
				{config.site.favicon ? (
					<link rel="icon" href={config.site.favicon} />
				) : null}
			</Helmet>
			<MarketplaceWebsite />
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
