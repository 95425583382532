import styles from "./ItemsPage.module.css";
import ItemGrid from "../layout/ItemGrid";

import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ url, items }) => (
    <div className={styles.page}>
        <ItemGrid baseUrl={url} items={items} />
    </div>
));
