import * as Backend from "@faintlines/backend-client";
import config from "./config";

import { makeAutoObservable, autorun, toJS, runInAction } from "mobx";
import store from "store";

const PERSIST_STORE_KEY = `state__${config.story.id}__${config.story.assetId}`;
const PERSIST_KEYS = ["sessionId", "coins", "purchasedItems", "soldItems"];

class State {
    loggingIn = false;
    loginError = "";
    sessionId = null;
    ongoingTransaction = false;

    // synced with server
    coins = null;
    purchasedItems = {};
    soldItems = {};

    constructor() {
        const existingState = store.get(PERSIST_STORE_KEY);
        if (existingState) {
            PERSIST_KEYS.forEach((key) => {
                if (existingState[key]) {
                    this[key] = existingState[key];
                }
            });
        }

        makeAutoObservable(this);

        autorun(() => {
            const persistedState = {};
            PERSIST_KEYS.forEach((key) => {
                persistedState[key] = toJS(this[key]);
            });
            store.set(PERSIST_STORE_KEY, persistedState);
        });

        this._tickers = [];
    }

    get loggedIn() {
        return this.sessionId !== null;
    }

    logIn(username, password) {
        this.loginError = "";
        this.loggingIn = true;

        Backend.storyInteract({
            interactionType: "login",
            data: {
                username,
                password,
            },
        })
            .then(({ data }) => {
                runInAction(() => {
                    const {
                        success,
                        session_id: sessionId,
                        coins,
                        purchased_items: purchasedItems,
                        sold_items: soldItems,
                        error,
                    } = data;
                    if (success) {
                        this.sessionId = sessionId;
                        this.coins = coins;
                        this.purchasedItems = purchasedItems || {};
                        this.soldItems = soldItems || {};
                    } else {
                        this.loginError = error || "Error! Please try again.";
                    }
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.loginError = "Error! Please try again.";
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loggingIn = false;
                });
            });
    }

    logOut() {
        this.sessionId = null;
        this.coins = null;
        this.purchasedItems = {};
        this.soldItems = {};
    }

    refresh() {
        if (!this.sessionId) {
            return;
        }

        Backend.storyInteract({
            interactionType: "refresh",
            sessionId: this.sessionId,
        })
            .then(({ data }) => {
                runInAction(() => {
                    const {
                        success,
                        logout,
                        purchased_items: purchasedItems,
                        sold_items: soldItems,
                        coins,
                    } = data;
                    if (success) {
                        if (logout) {
                            this.logOut();
                        } else {
                            this.coins = coins;
                            this.purchasedItems = purchasedItems;
                            this.soldItems = soldItems;
                        }
                    }
                });
            })
            .catch(() => {
                // nothing
            });
    }

    buyItem(item, text) {
        if (this.ongoingTransaction) {
            return null;
        }

        this.ongoingTransaction = true;

        return new Promise((resolve) => {
            Backend.storyInteract({
                interactionType: "buy",
                sessionId: this.sessionId,
                data: {
                    item_id: item.id,
                    text,
                },
            })
                .then(({ data }) => {
                    runInAction(() => {
                        const {
                            success,
                            error,
                            purchased_item: purchasedItem,
                            coins,
                        } = data;
                        if (success) {
                            this.coins = coins;
                            this.purchasedItems[item.id] = purchasedItem;
                        }
                        resolve({ success, item: purchasedItem, error });
                    });
                })
                .catch(() => {
                    resolve({ success: false });
                })
                .finally(() => {
                    runInAction(() => {
                        this.ongoingTransaction = false;
                    });
                });
        });
    }

    sellItem(item, text) {
        if (this.ongoingTransaction) {
            return null;
        }

        this.ongoingTransaction = true;

        return new Promise((resolve) => {
            Backend.storyInteract({
                interactionType: "sell",
                sessionId: this.sessionId,
                data: {
                    item_id: item.id,
                    text,
                },
            })
                .then(({ data }) => {
                    runInAction(() => {
                        const { success, error, message, coins } = data;
                        if (success) {
                            this.coins = coins;
                            this.soldItems[item.id] = message;
                        }
                        resolve({ success, message, error });
                    });
                })
                .catch(() => {
                    resolve({ success: false });
                })
                .finally(() => {
                    runInAction(() => {
                        this.ongoingTransaction = false;
                    });
                });
        });
    }
}

const state = new State();

export default state;
