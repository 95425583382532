import styles from "./ItemGrid.module.css";

import { Link } from "react-router-dom";
import urljoin from "url-join";
import React from "react";

export default function ItemGrid({ baseUrl, items }) {
	const filteredItems = items.filter((item) => item.visible !== false);

	return (
		<div className={styles.grid}>
			{filteredItems.map((item) => (
				<Item key={item.id} baseUrl={baseUrl} item={item} />
			))}
		</div>
	);
}

function Item({ baseUrl, item }) {
	return (
		<Link to={urljoin(baseUrl, item.id)} className={styles.item}>
			<div
				className={styles.item__image}
				style={{ backgroundImage: `url(${item.image})` }}
			/>
			<div className={styles.item__info}>
				<div className={styles.item__title}>{item.title}</div>
				<div className={styles.item__price}>
					{item.price.toLocaleString()}
				</div>
			</div>
		</Link>
	);
}
