/* eslint-disable react/button-has-type */
import styles from "./buttons.module.css";

import classnames from "classnames";
import React from "react";

export function Button({
	text,
	inline,
	large,
	disabled,
	loading,
	className,
	type,
	onClick,
}) {
	return (
		<button
			type={type}
			className={classnames(
				styles.button,
				className,
				inline ? styles.inline : null,
				large ? styles.large : null,
				disabled ? styles.disabled : null,
				loading ? styles.loading : null
			)}
			onClick={onClick}
		>
			{text}
		</button>
	);
}
